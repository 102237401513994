import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { start } from "repl";
import { keyExists } from "../common/commonFunctions";
import deepCopy from "../common/deepcopy";
import ActionsIDFuncLookup from "../types/actions";

const initialState: { name: string } = {
  name: "",
};

const businessNameInfoSlice = createSlice({
  name: "business-info-slice",
  initialState,
  reducers: {
    updateName: (state, action: PayloadAction<{ name: string }>) => {
      state.name = action.payload.name;
    },
  },
});

export const businessNameInfoActions = businessNameInfoSlice.actions;
export default businessNameInfoSlice;
