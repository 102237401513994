import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { width: number } = { width: window.innerWidth };

const screenWidthSlice = createSlice({
  name: "screen-width-slice",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<{ width: number }>) => {
      state.width = action.payload.width;
    },
  },
});

export const screenWidthActions = screenWidthSlice.actions;
export default screenWidthSlice;
