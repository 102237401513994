import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { start } from "repl";
import { keyExists } from "../common/commonFunctions";
import deepCopy from "../common/deepcopy";
import ActionsIDFuncLookup from "../types/actions";

const initialState: { name: string; refresh: boolean } = {
  name: "",
  refresh: false,
};

const headerNameInfoSlice = createSlice({
  name: "header-info-slice",
  initialState,
  reducers: {
    updateName: (state, action: PayloadAction<{ name: string }>) => {
      state.name = action.payload.name;
    },
    setRefresh: (state, action: PayloadAction<{ val: boolean }>) => {
      state.refresh = action.payload.val;
    },
  },
});

export const headerNameInfoActions = headerNameInfoSlice.actions;
export default headerNameInfoSlice;
