import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSessionDetails } from "../common/cookies";
import { LoginInfo } from "../types/data_types";

const { id } = getSessionDetails();

const initialState: LoginInfo = {
  isLoggedIn: !!id,
  bhpPromoLoaded: false,
  bhpEditMode: false,
  bhpVerify: false,
  promptFeedbackNDirectionDone: false,
};

const loginInfoSlice = createSlice({
  name: "login-info-slice",
  initialState,
  reducers: {
    updateInfo: (state, action: PayloadAction<{ loginInfo: boolean }>) => {
      // console.log("Update the login status ", action.payload.loginInfo);
      state.isLoggedIn = action.payload.loginInfo;
    },
    updatePromoLoaded: (state, action: PayloadAction<{ loaded: boolean }>) => {
      // console.log("Update the login status ", action.payload.loginInfo);
      state.bhpPromoLoaded = action.payload.loaded;
    },
    updateBhpEditMode: (state, action: PayloadAction<{ loaded: boolean }>) => {
      // console.log("Update the login status ", action.payload.loginInfo);
      state.bhpEditMode = action.payload.loaded;
    },
    updateBhpVerify: (state, action: PayloadAction<{ loaded: boolean }>) => {
      // console.log("Update the login status ", action.payload.loginInfo);
      state.bhpVerify = action.payload.loaded;
    },
    updatePromptFeedbackNDirectionDone: (
      state,
      action: PayloadAction<{ val: boolean }>
    ) => {
      // console.log("Update the login status ", action.payload.loginInfo);
      state.promptFeedbackNDirectionDone = action.payload.val;
    },
  },
});

export const loginInfoActions = loginInfoSlice.actions;
export default loginInfoSlice;
