import React, { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { screenWidthActions } from "../store/screen-width-slice";

const ResponsiveSubscriber: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleWindowResize = () => {
      dispatch(screenWidthActions.update({ width: window.innerWidth }));
    };

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return <div></div>;
};

export default ResponsiveSubscriber;
