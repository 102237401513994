import { AlertButton, IonAlert } from "@ionic/react";
import React from "react";
import { AlertWithExecTypes } from "../types/data_types";

const AlertWithExec: React.FC<{
  openAlert: AlertWithExecTypes;
  setOpenAlert: (openAlert: AlertWithExecTypes) => void;
  disableCancel?: boolean;
}> = (props) => {
  const buttons: (string | AlertButton)[] = [
    {
      text: props.openAlert.primaryButtonName
        ? props.openAlert.primaryButtonName
        : "Yes",
      handler: () => {
        props.openAlert.executeFunc(props.openAlert.funcInputs);
      },
      role: "confirm",
    },
  ];
  if (!props.disableCancel && !props.openAlert?.disableCancel) {
    buttons.push(
      props.openAlert?.cancelButtonName
        ? props.openAlert.cancelButtonName
        : "Cancel"
    );
  }
  return (
    <IonAlert
      isOpen={props.openAlert.open}
      onDidDismiss={() => {
        props.setOpenAlert({
          message: "",
          open: false,
          executeFunc: undefined,
          funcInputs: {},
        });
      }}
      buttons={buttons}
      header={props.openAlert.header ? props.openAlert.header : "Alert!"}
      message={props.openAlert.message}
    />
  );
};

export default AlertWithExec;
