import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { start } from "repl";
import { keyExists } from "../common/commonFunctions";
import deepCopy from "../common/deepcopy";
import ActionsIDFuncLookup from "../types/actions";

const initialState: { name: string; phone: string; reviewId: number } = {
  name: "",
  phone: "",
  reviewId: 0,
};

const reviewCedentialsInfoSlice = createSlice({
  name: "review-credentials-info-slice",
  initialState,
  reducers: {
    updateName: (state, action: PayloadAction<{ name: string }>) => {
      state.name = action.payload.name;
    },
    updatePhone: (state, action: PayloadAction<{ phone: string }>) => {
      state.phone = action.payload.phone;
    },
    updateReviewId: (state, action: PayloadAction<{ id: number }>) => {
      state.reviewId = action.payload.id;
    },
  },
});

export const reviewCredentialsInfoActions = reviewCedentialsInfoSlice.actions;
export default reviewCedentialsInfoSlice;
