import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { start } from "repl";
import { keyExists } from "../common/commonFunctions";
import deepCopy from "../common/deepcopy";
import ActionsIDFuncLookup from "../types/actions";
import { TeacherInfo } from "../types/data_types";

const initialState: TeacherInfo = {
  name: "",
  id: "",
  phoneNumber: -1,
  schoolId: "",
  subjectTeacher: [],
  currentSession: { id: "", name: "" },
  city: "",
  linkedInUrl: "",
  state: "",
};

const teacherInfoSlice = createSlice({
  name: "teacher-info-slice",
  initialState,
  reducers: {
    updateDisplaySchoolId: (state, action: PayloadAction<string>) => {
      state.displaySchoolId = action.payload;
    },
    updateInfo: (state, action: PayloadAction<TeacherInfo>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.schoolId = action.payload.schoolId;
      state.subjectTeacher = action.payload.subjectTeacher;
      state.currentSession = action.payload.currentSession;
      if (keyExists("classTeacher", action.payload)) {
        state.classTeacher = {
          class: action.payload.classTeacher.class,
          section: action.payload.classTeacher.section,
          coScholastics: action.payload.classTeacher.coScholastics,
        };
      }
      if (keyExists("schoolName", action.payload)) {
        state.schoolName = action.payload.schoolName;
      }
      if (keyExists("phoneNumber", action.payload)) {
        state.phoneNumber = action.payload.phoneNumber;
      }
      // Remove this
      // else {
      // state.schoolName = "St. Columba's School";
      // }
      if (keyExists("associateInstitutes", action.payload)) {
        state.associateInstitutes = deepCopy(
          action.payload.associateInstitutes
        );
      }
      if (keyExists("city", action.payload)) {
        state.city = deepCopy(action.payload.city);
      }
      if (keyExists("state", action.payload)) {
        state.state = deepCopy(action.payload.state);
      }
      if (keyExists("linkedInUrl", action.payload)) {
        state.linkedInUrl = deepCopy(action.payload.linkedInUrl);
      }
      // Remove this
      // else {
      // state.associateInstitutes = [];
      // state.associateInstitutes.push({
      // id: "1",
      // name: "Institute Name 1, Place 1, State 1",
      // });
      // state.associateInstitutes.push({
      // id: "2",
      // name: "Institute Name 2, Place 2, State 2",
      // });
      // state.associateInstitutes.push({
      // id: "3",
      // name: "Institute Name 3, Place 3, State 3",
      // });
      // }
    },
  },
});

export const teacherInfoActions = teacherInfoSlice.actions;
export default teacherInfoSlice;
