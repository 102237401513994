import { base64FromPath } from "@ionic/react-hooks/filesystem";
import { min } from "moment";
import { useAppDispatch } from "../app/hooks";
import { iprofileInfoActions } from "../store/iprofile-slice";
import {
  IDIGIZEN_BACKEND_URL,
  IDIGIZEN_FRONTEND_URL,
  getFileThumbnailDB,
  API_SECURE_KEY,
} from "../types/constants";
import axios from "axios";

import {
  AccoladeDataType,
  AccoladeType,
  FileBundleType,
  IprofileAcademicScorePerClass,
  PositionType,
  ProfileDataType,
  SubjectIntelligenceTraits,
  TeacherInfo,
} from "../types/data_types";
import {
  fetchQueries,
  getMarksGql,
  getStudentAccoladeQuery,
  getSubjectTraitsListGql,
} from "./gql_queries";
import {
  onClickDestinationTypesPageMapping,
  ClickActionIDType,
  UniversalDataType,
  TableStructure,
  ColumnStructure,
  inputTableColumnTypes,
  mobileWidthBreakpoint,
} from "../types/form_table_data_types";
import EXIF from "exif-js";
import { Geolocation, Position } from "@capacitor/geolocation";
import deepCopy from "./deepcopy";
import { Camera, CameraResultType } from "@capacitor/camera";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import imageCompression from "browser-image-compression";
import { SeoUrlDetailsType } from "../pages/BussinessHomePage";

const getPrintDateDDMMYYYY = (
  dateVal: string,
  noDash: boolean = false
): string => {
  if (!dateVal) {
    return "";
  }
  const year = dateVal.slice(0, 4);
  const month = dateVal.slice(5, 7);
  const day = dateVal.slice(8, 10);
  const yyyymmdd = year + month + day;
  return `${yyyymmdd.slice(6)}${!noDash ? "-" : ""}${yyyymmdd.slice(4, 6)}${
    !noDash ? "-" : ""
  }${yyyymmdd.slice(0, 4)}`;
};

const getDateStringFromDateISO = (date: string): string => {
  return date.slice(0, 10);
};

const getDateStringFromDateObj = (date: Date): string => {
  return getDateStringFromDateISO(date.toISOString());
};

const getYearList = (howManyYears: number = 1): string[] => {
  const currentYear = new Date().getFullYear();
  const retVal: string[] = [];
  retVal.push(`${currentYear}`);
  for (let i = 1; i < howManyYears; i++) {
    retVal.push(`${currentYear - i}`);
  }
  return retVal;
};

const convertMonthIndexToMonthNumberString = (idx: number): string => {
  if (idx === 0) return "01";
  if (idx === 1) return "02";
  if (idx === 2) return "03";
  if (idx === 3) return "04";
  if (idx === 4) return "05";
  if (idx === 5) return "06";
  if (idx === 6) return "07";
  if (idx === 7) return "08";
  if (idx === 8) return "09";
  if (idx === 9) return "10";
  if (idx === 10) return "11";
  if (idx === 11) return "12";
};

const searchStringSuccess = (searchString: string, data: string): boolean => {
  //console.log("searchString =", searchString, "  data =", data);

  const isVisible =
    searchString === "" ||
    (typeof searchString === "string" &&
      searchString !== "" &&
      data &&
      data.toLowerCase().includes(searchString.toLowerCase()));
  return isVisible;
};

const getParamsFromSearchString = (str: string): any => {
  const urlSearchParams = new URLSearchParams(str);
  return Object.fromEntries(urlSearchParams.entries());
};

const keyExists = (key: string, obj: { [key: string]: any }) => {
  if (obj === undefined) return false;
  let isExists = key in obj;
  if (!isExists || obj[key] === undefined || obj[key] === null) return false;
  return true;
};

const isEmailValid = (inp: string): boolean => {
  const emailRegExp = RegExp(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)(\.\w{2,3})+$/);
  return emailRegExp.test(inp);
};

const isPhoneValid = (inp: string): boolean => {
  //regular expression to check 10 continuous numbers
  const phoneRegEx = RegExp(/^\d{10}$/);
  return phoneRegEx.test(inp);
  //return inp.length < 10;
};

const convertArrayToBlob = (inp: string[]): string => {
  let concatString = "";
  let isFirst: boolean = true;
  for (const sval of inp) {
    if (isFirst) {
      isFirst = false;
      concatString = concatString + `${sval}`;
    } else concatString = concatString + `|${sval}`;
  }
  return concatString;
};

const convertBlobToArray = (inp: string): string[] => {
  return inp.split("|");
};

const monthStrToNum = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

const getMonthNameFromMonthNum = (month: string): string => {
  // console.log(month);
  const isString = typeof month === "string";
  if ((isString && month === "01") || (!isString && month === 1)) return "Jan";
  if ((isString && month === "02") || (!isString && month === 2)) return "Feb";
  if ((isString && month === "03") || (!isString && month === 3)) return "Mar";
  if ((isString && month === "04") || (!isString && month === 4)) return "Apr";
  if ((isString && month === "05") || (!isString && month === 5)) return "May";
  if ((isString && month === "06") || (!isString && month === 6)) return "Jun";
  if ((isString && month === "07") || (!isString && month === 7)) return "Jul";
  if ((isString && month === "08") || (!isString && month === 8)) return "Aug";
  if ((isString && month === "09") || (!isString && month === 9)) return "Sep";
  if ((isString && month === "10") || (!isString && month === 10)) return "Oct";
  if ((isString && month === "11") || (!isString && month === 11)) return "Nov";
  if ((isString && month === "12") || (!isString && month === 12)) return "Dec";
};

const getMonth = (dateVal: string): string => {
  return dateVal.slice(5, 7);
};

const getDateMonth = (dateVal: string): string => {
  const month = dateVal.slice(5, 7);
  const day = dateVal.slice(8, 10);
  const monthName = getMonthNameFromMonthNum(month);
  return `${day} ${monthName}`;
};

const getAge = (dateString: string): number | string => {
  // console.log("DOB ", dateString);
  if (!dateString) return "-";
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const addTimeHHMM = (time: string, increaseByMin: number): string => {
  let [hour, minute] = time.split(":").map((el) => parseInt(el));
  minute = minute + increaseByMin;
  if (minute >= 60) {
    hour = hour + 1;
    minute = minute % 60;
  }
  const hourString = hour <= 9 ? `0${hour}` : `${hour}`;
  const minuteString = minute <= 9 ? `0${minute}` : `${minute}`;
  return `${hourString}:${minuteString}`;
};

const subtractTimeHHMM = (time: string, reduceByMin: number): string => {
  let [hour, minute] = time.split(":").map((el) => parseInt(el));
  minute = minute - reduceByMin;
  if (minute < 0) {
    hour = hour - 1;
    minute = minute + 60;
  }
  const hourString = hour <= 9 ? `0${hour}` : `${hour}`;
  const minuteString = minute <= 9 ? `0${minute}` : `${minute}`;
  return `${hourString}:${minuteString}`;
};

const getTimeFromHHMM = (hhmm: string): string => {
  if (!hhmm) return "--:-- AM";
  const hourStr = hhmm.substring(0, 2);
  const minStr = hhmm.substring(3);
  const hour = parseInt(hourStr);
  const ampm = hour >= 12 ? "PM" : "AM";
  const hourReduced =
    hour === 0 || hour === 12 ? 12 : hour <= 11 ? hour : hour - 12;
  const hourString = hourReduced <= 9 ? `0${hourReduced}` : `${hourReduced}`;
  return `${hourString}:${minStr} ${ampm}`;
};

const getTimeFromAmPm = (hh: string, mm: string, ampm: string): string => {
  console.log("HH ", hh, " MM ", mm, " ", ampm);
  const hhVal = parseInt(hh);
  // const hourVal =
  //   hhVal === 12 ?( ampm === "AM"?0:12): ? 0 : ampm === "PM" ? hhVal + 12 : hhVal;
  let hourVal = 0;
  if (hhVal === 12) {
    if (ampm === "AM") hourVal = 0;
    else hourVal = 12;
  } else if (ampm === "PM") hourVal = hhVal + 12;
  else hourVal = hhVal;
  const hourValString = hourVal <= 9 ? `0${hourVal}` : `${hourVal}`;
  return `${hourValString}:${mm}`;
};

const getTimeDurationInMinutes = (
  startTime: string,
  endTime: string
): number => {
  const startHr = parseInt(startTime.substring(0, 2));
  const endHr = parseInt(endTime.substring(0, 2));
  const startMin = parseInt(startTime.substring(3));
  const endMin = parseInt(endTime.substring(3));
  const startTimeInMin = startHr * 60 + startMin;
  const endTimeInMin = endHr * 60 + endMin;
  return endTimeInMin - startTimeInMin;
};

const getSchoolName = (id: string, userInfo: TeacherInfo): string => {
  if (userInfo.schoolId == id) {
    if (keyExists("schoolName", userInfo)) return userInfo.schoolName;
    else return "";
  } else if (keyExists("associateInstitutes", userInfo)) {
    for (const inst of userInfo.associateInstitutes) {
      if (inst.id == id) return inst.name;
    }
  }
  return "";
};

const splitAccoladesInYearChunks = (
  inp: AccoladeDataType[]
): { [key: string]: AccoladeDataType[] } => {
  const retVal: { [key: string]: AccoladeDataType[] } = {};
  if (!inp || !inp.length) return retVal;
  for (const accolade of inp) {
    let keyName = accolade.date;
    if (!keyName) {
      keyName = "untagged";
    }
    if (!keyExists(keyName, retVal)) retVal[keyName] = [];
    retVal[keyName].push(accolade);
  }
  console.log("Year separated accolade ", retVal);
  return retVal;
};

const getBlobFromDataURI = (dataURI: string): Blob => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

const convertToFormData = async (
  fileArr: FileBundleType[]
): Promise<FormData> => {
  const formData = new FormData();
  let hasNewFiles = false;
  for (const file of fileArr) {
    if (!!file.id) continue;
    hasNewFiles = true;
    if (file.blob) await convertFileToFormData(file, formData);
    else await convertImgToFormData(file, formData);
  }
  if (hasNewFiles) return formData;
  else return undefined;
};

async function getPhotoRotation(base64Data) {
  try {
    // const blob = base64ToBlob(base64Data);
    const blob = getBlobFromDataURI(base64Data);

    const arrayBuffer = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsArrayBuffer(blob);
    });

    const exifData = EXIF.readFromBinaryFile(arrayBuffer);
    const orientation = exifData.Orientation;
    const rotation =
      orientation === 6 ? 1 : orientation === 3 ? 2 : orientation === 8 ? 3 : 0;
    return rotation;
  } catch (e) {
    console.log("Get Photo rotation error ", e);
  }
}

const getRotationInfo = async (imgData: FileBundleType): Promise<number> => {
  try {
    const base64Data = await base64FromPath(imgData.url);
    const rotation: number = await getPhotoRotation(base64Data);
    console.log("Get rotation info ", rotation);
    return rotation;
  } catch (e) {
    console.log("Rotation call ", e);
  }
};

const convertImgToFormData = async (
  imgData: FileBundleType,
  formData: FormData
): Promise<[string, Blob]> => {
  console.log("Create form data to save ", imgData);
  const base64 = await base64FromPath(imgData.url);
  const r = Math.floor(Math.random() * 16);
  const imgName = r.toString(16) + `.${imgData.type}`;
  formData.append("file", getBlobFromDataURI(base64), imgName);
  if (keyExists("rotation", imgData)) {
    formData.append(`rotation-${imgName}`, `${imgData.rotation}`);
  }
  return [imgName, getBlobFromDataURI(base64)];
};

const convertFileToFormData = async (
  file: FileBundleType,
  fileData: FormData
): Promise<void> => {
  console.log("Save file ", file);
  const rawFile = new File([file.blob], file.name, {
    type: file.type,
  });
  if (keyExists("rotation", file)) {
    fileData.append(`rotation-${file.name}`, `${file.rotation}`);
  }
  fileData.append("file", rawFile, file.name);
  //read the file into the buffer
};

const getImgUrlFromFormData = (imgForm: FormData): string => {
  const data: any = imgForm.get("img");
  return URL.createObjectURL(data);
};

const getPercentage = (marks: IprofileAcademicScorePerClass): number => {
  if (!marks) return 0;
  let totalMarks: number = 0;
  let marksObtained: number = 0;
  for (const subject of marks.marks) {
    totalMarks = totalMarks + subject.totalMarks;
    marksObtained = marksObtained + subject.marks;
  }
  return Math.round((marksObtained / totalMarks) * 100 * 10) / 10;
};

const isCertificate = (type: string): boolean => {
  return type === "Merit Certificates" || type === AccoladeType.certificate;
};

const convertGqlDataToProfileData = (data: any): ProfileDataType => {
  console.log("convertGqlDataToProfileData:: ", data);
  const profileDataL: ProfileDataType = {
    name: `${data.data.allUserdata.Userdata[0].firstname} ${
      !!data.data.allUserdata.Userdata[0].lastname
        ? data.data.allUserdata.Userdata[0].lastname
        : ""
    }`,
    profileName: "",
    aboutMe: !!data.data.allUserdata.Userdata[0].biography
      ? data.data.allUserdata.Userdata[0].biography
      : "-",
    dateOfBirth: !!data.data.allUserdata.Userdata[0].dob
      ? data.data.allUserdata.Userdata[0].dob
      : "",
    id: data.data.allUserdata.Userdata[0].id,
    img: !!data.data.allUserdata.Userdata[0].profileimage
      ? data.data.allUserdata.Userdata[0].profileimage
      : "",
    // snapshot: { certificates: 0, certification: 0, merit: 0 },
  };
  if (!!profileDataL.name) {
    const names = profileDataL.name.split(" ");
    profileDataL.profileName = names[0];
  }
  profileDataL.accolades = [];
  for (const doc of data.data.allUserdata.Userdata[0].DocumentUser.Documents) {
    const accolade: AccoladeDataType = {
      date: doc.when,
      class: doc.docgrade,
      imgName: doc.filename,
      description: doc.docdescription,
      isMerit: doc.accolatetype,
      type: doc.what,
      id: doc.id,
      doctitle: doc.doctitle,
      isScholastic: doc.type === "Scholastic" ? true : false,
    };
    accolade.userDefinedActivity = doc.usercategory;
    if (!!doc.categoryid) {
      accolade.category = {
        id: doc.categoryid,
        name: doc.documentcatrel.Clusterstables[0]?.name,
      };
    } else {
      accolade.category = {
        id: 0,
        name: doc.documentcatrel.Clusterstables[0]?.name,
      };
    }
    if (!!doc.activityid) {
      accolade.activity = {
        id: doc.activityid,
        name: doc.documentactivityrel.Ecaactivityjobtitles[0]?.name,
      };
    } else {
      accolade.activity = {
        id: 0,
        name: doc.documentactivityrel.Ecaactivityjobtitles[0]?.name,
      };
    }
    // if (isCertificate(accolade.type)) {
    //   accolade.type = AccoladeType.certificate;
    // }
    // if (accolade.type === AccoladeType.certification)
    //   profileDataL.snapshot.certification++;
    profileDataL.accolades.push(accolade);
  }
  console.log("Got profile data", profileDataL);

  return profileDataL;
};

const getStudentAccolades = async (studentId: string, dispatch: any) => {
  try {
    console.log("Called for iProfile");
    const data = await fetchQueries(getStudentAccoladeQuery, {
      where: {
        id: studentId,
      },
    });
    console.log("Get student accolades ", data);
    let profileDataL = convertGqlDataToProfileData(data);
    let didUpdate = false;
    // setProfileData(profileDataL);
    console.log("Set the profile data ", profileDataL);
    dispatch(
      iprofileInfoActions.updateIprofile({ iprofileInfo: profileDataL })
    );
    // if (didUpdate) setRefresh((val) => !val);

    const res = await fetchQueries(getMarksGql, {
      where: { studentid: studentId, active: 1 },
    });
    // Todo: set the academic records
    console.log("Received report card data ", res);
    const academicRecordL: IprofileAcademicScorePerClass[] = [];
    for (const record of res.data.allStudentmarks.Studentmarks) {
      let academicRecordClass: IprofileAcademicScorePerClass =
        academicRecordL.find((el) => el.documentId === record.documentid);
      if (!academicRecordClass) {
        academicRecordClass = {
          documentId: record.documentid,
          marks: [],
          standard: record.class,
        };
        academicRecordL.push(academicRecordClass);
      }
      academicRecordClass.marks.push({
        id: record.id,
        marks: record.marks,
        subjectId: record.subjectmasterid,
        subjectName: record.subjectname,
        totalMarks: record.maxmarks,
      });
    }
    console.log("academic record consolidated ", academicRecordL);
    // setAcademicScore(academicRecordL);
    dispatch(
      iprofileInfoActions.updateAcademicScore({
        academicScore: academicRecordL,
      })
    );
  } catch (e) {
    // setError(JSON.stringify(e));
    // presentToast(e);
    console.log("Received error ", e);
    throw e;
  }
};

const getSubjectTraitsList = async (dispatch: any) => {
  try {
    const data = await fetchQueries(getSubjectTraitsListGql, {
      where: {
        schoolid: 1,
      },
    });
    const subjectTraitsListL: SubjectIntelligenceTraits[] = [];
    for (const subject of data.data.allSubjects.Subjects) {
      const subjectTraitsL: SubjectIntelligenceTraits = {
        id: subject.id,
        subject: subject.name,
        intelligence: [],
        traits: [],
      };
      for (const traitItem of subject.subject_id_mapping_idx
        .Subjectinteltraitmappings) {
        if (
          traitItem.intelTraitIdMappingIdxrel.Traitsintelligencelists[0]
            .type === "intelligence"
        ) {
          subjectTraitsL.intelligence.push(
            traitItem.intelTraitIdMappingIdxrel.Traitsintelligencelists[0]
          );
        } else if (
          traitItem.intelTraitIdMappingIdxrel.Traitsintelligencelists[0]
            .type === "trait"
        ) {
          subjectTraitsL.traits.push(
            traitItem.intelTraitIdMappingIdxrel.Traitsintelligencelists[0]
          );
        } else {
        }
      }
      subjectTraitsListL.push(subjectTraitsL);
    }
    // setSubjectTraitsList(subjectTraitsListL);
    dispatch(
      iprofileInfoActions.updateSubjectTraitsList({
        subjectTraitsList: subjectTraitsListL,
      })
    );
    console.log("Data from subject traits ", subjectTraitsListL);
  } catch (e) {
    throw e;
  }
};

const getDateIso = (inp: Date, backSlash: boolean = false): string => {
  // console.log(inp);
  const date = inp.getDate();
  const dateStr = date < 10 ? `0${date}` : `${date}`;
  const month = inp.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : `${month}`;
  const year = inp.getFullYear();
  if (!backSlash) return `${year}-${monthStr}-${dateStr}`;
  else return `${year}/${monthStr}/${dateStr}`;
};

const constructCustomParam = (
  param: string,
  rowData: UniversalDataType
): string => {
  let findIdentifier = "";
  let retval = "";
  let foundIndentifier = false;
  const parts = param.split("=");
  for (const char of parts[1]) {
    if (char === "%") {
      if (foundIndentifier) {
        retval = retval + `${rowData[findIdentifier].value}`;
      } else {
        findIdentifier = "";
      }
      foundIndentifier = !foundIndentifier;
    } else if (!foundIndentifier) {
      retval = retval + char;
    } else if (foundIndentifier) {
      findIdentifier = findIdentifier + char;
    }
  }
  return `${parts[0]}=${encodeURIComponent(retval)}`;
};

const onClickAction = (
  src: string,
  action: ClickActionIDType,
  rowData: UniversalDataType,
  history: any,
  colIdentifier?: string
) => {
  //destination id is either explicitly specified at creation time or is the id of the element clicked
  const destId = action.id ? action.id : rowData[colIdentifier].id;
  let queryString = `&id=${destId}`;
  let idx = 0;
  console.log(action);
  for (const dep of action.params) {
    //queryString = queryString.concat(`&param${idx++}=${rowData[dep].value}`);
    queryString = queryString.concat(
      `&${dep}=${encodeURIComponent(rowData[dep].value)}`
    );
  }
  if (action.customParams) {
    for (const param of action?.customParams) {
      queryString = queryString.concat(
        `&${constructCustomParam(param, rowData)}`
      );
    }
  }
  const path = onClickDestinationTypesPageMapping[action.component];
  history.push(`${path}${queryString}`);
};

const convertImgJsonToObj = (
  imgJson: string
): { fileName: string; rotation: number; imgCss: string } => {
  let value = { fileName: "", rotation: 0, imgCss: "" };
  try {
    if (!!imgJson) {
      value = JSON.parse(imgJson);
      if (value) {
        value.imgCss =
          value.rotation === 1
            ? "table-element-pic-rotate-90"
            : value.rotation === 2
            ? "table-element-pic-rotate-180"
            : value.rotation === 3
            ? "table-element-pic-rotate-270"
            : "";
      }
    }
  } catch (e) {
    value.fileName = imgJson;
    value.rotation = 0; //default rotation
    value.imgCss = "";
  }
  return value;
};

const getCurrentLocation = async (): Promise<PositionType> => {
  try {
    // const permission = await Geolocation.requestPermissions();
    // console.log("Permissions ", permission);
    // if (permission.location === "granted") {
    //   console.log("Permission granted");
    // } else {
    //   console.log("Permission denied");
    // }
    const retVal: Position = await Geolocation.getCurrentPosition();
    return {
      coordinate: {
        accuracy: retVal.coords.accuracy,
        latitude: retVal.coords.latitude,
        longitude: retVal.coords.longitude,
      },
      timestamp: retVal.timestamp,
    };
  } catch (e) {
    console.log("ERROR in Geolocation ", e);
  }
};

function getPlatform() {
  if (
    window.navigator.userAgent.indexOf("iPhone") !== -1 ||
    window.navigator.userAgent.indexOf("iPad") !== -1
  ) {
    return "ios";
  } else if (window.navigator.userAgent.indexOf("Android") !== -1) {
    return "android";
  } else {
    return "desktop";
  }
}

const goToGoogleMapsNav = (lat, long) => {
  // window.location.assign(`https://www.google.com/maps/?q=${lat},${long}`);
  const platform = getPlatform();

  switch (platform) {
    case "ios":
      // Try opening in native Google Maps app on iOS
      window.location.assign(
        `comgooglemaps://?q=${lat},${long}&directionsmode=driving`
      );
      break;
    case "android":
      // Try opening in native Google Maps app on Android
      window.location.assign(`geo:${lat},${long}?q=${lat},${long}`);
      break;
    default:
      // Open Google Maps in a browser (Desktop or if native app fails)
      window.open(`https://www.google.com/maps/?q=${lat},${long}`, "_blank");
      break;
  }
};

const checkDomainName = (domainName: string): string => {
  if (!domainName) {
    return ""; //by pass for empty domain names for directory
  }
  if (domainName.length < 1 || domainName.length > 50) {
    return `1:Domain name should be between 1 and 50 characters, 
            domain name length = ${domainName.length}`;
  }
  const pattern = /^(?=.{1,50}$)[a-zA-Z0-9_.-]+$/;
  if (pattern.test(domainName)) {
    return "";
  }
  // Initialize an array to hold offending characters
  const offendingChars = [];

  // Check each character individually
  for (let i = 0; i < domainName.length; i++) {
    const char = domainName[i];
    // If the test string matches, the current character is offending
    if (!pattern.test(char)) {
      offendingChars.push(`${i}:${char}`);
    }
  }

  return offendingChars.length > 0 ? offendingChars.join(",") : null;
};

const transformTableStructForCluster = (
  tableStruct: TableStructure
): TableStructure => {
  if (tableStruct.clustering && tableStruct.clustering.length) {
    const retVal: TableStructure = deepCopy(tableStruct);
    for (const cluster of tableStruct.clustering) {
      const uids = cluster.uidList.split(",");
      const index = retVal.columnStructure.findIndex(
        (el) => el.uniqueIdentifier === uids[0]
      );
      const columnCluster: ColumnStructure = {
        canEdit: true,
        hasFilter: false,
        id: retVal.columnStructure[index].id,
        imgCropperRatio: 0,
        isMandatory: false,
        label: cluster.groupName,
        pcVertColSize: retVal.columnStructure[index].pcVertColSize,
        placeholder: "",
        size: retVal.columnStructure[index].size,
        type: inputTableColumnTypes.imageCluster,
        uniqueIdentifier: "FILLER",
        implementation: [],
      };
      // retVal.columnStructure.push(columnCluster);
      columnCluster.implementation.push(retVal.columnStructure[index]);
      retVal.columnStructure[index] = columnCluster;
      for (const uid of uids) {
        const index = retVal.columnStructure.findIndex(
          (el) => el.uniqueIdentifier === uid
        );
        if (index >= 0) {
          columnCluster.implementation.push(retVal.columnStructure[index]);
          retVal.columnStructure.splice(index, 1);
        }
      }
      columnCluster.uniqueIdentifier = uids[0];
    }
    return retVal;
  } else {
    return tableStruct;
  }
};

/********************************************************************************
 * Function Name: compressImage
 * Description: Function to compress the image to given quality and dimensions.
 ********************************************************************************/
const compressImage = async (
  file: File,
  maxSizeInMB: number,
  width: number,
  height: number
): Promise<File> => {
  const options = {
    maxSizeMB: maxSizeInMB,
    maxWidthOrHeight: width > height ? width : height,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Error compressing image:", error);
    return null;
  }
}; //End of compressImage

/********************************************************************************
 * Function Name: compressBob
 * Description: Wrapper function on top of compressImage to handle back and forth
 *              of blob to file and vice versa.
 ********************************************************************************/
const compressBlob = async (
  blob: Blob,
  blobType: string,
  maxFileSize: number,
  maxHeigth: number,
  maxWidth: number
): Promise<Blob> => {
  try {
    //compress the blob and update in the file desc.
    const blob2FileOptions = {
      type: blobType,
      lastModified: Date.now(),
    };
    const tmpFileName = new File(
      [blob],
      "tmp_fname_for_compression",
      blob2FileOptions
    );
    const compressedImage: File = await compressImage(
      tmpFileName,
      maxFileSize,
      maxWidth,
      maxHeigth
    );

    //convert te file back to blob
    return new Blob([compressedImage], { type: compressedImage.type });
  } catch (err) {
    console.log("Failed to compress the blob, error =", err);
  }
}; //End of compressBlob

const getPhoto = async (isUpload: boolean): Promise<FileBundleType> => {
  try {
    const file: FileBundleType = {
      name: "",
      url: "",
      id: "",
      blob: undefined,
      type: undefined,
    };
    if (!isUpload) {
      const options = { resultType: CameraResultType.Uri };
      const image = await Camera.getPhoto(options);
      const base64String = await base64FromPath(image.webPath);
      image.base64String = base64String;
      // const img = await takePhoto(options);

      const blob = getBlobFromDataURI(image.base64String);

      file.name = "";
      file.url = image.webPath;
      file.id = "";
      file.type = image.format;
      file.blob = blob;

      file.rotation = await getRotationInfo(file);
    } else {
      const data = await FilePicker.pickFiles();
      if (data && data.files.length > 0) {
        file.name = data.files[0].name;
        file.type = data.files[0].mimeType.split("/")[1];
        file.blob = data.files[0].blob;
        file.url = URL.createObjectURL(file.blob);
        // file.rotation = 0;
        file.rotation = await getRotationInfo(file);
      } else {
        return undefined;
      }
    }

    // file.blob = await compressBlob(file.blob, file.blob.type, 1, 640, 480);

    return file;
  } catch (e) {
    console.log("File picker error ", e);
    // if (e.message !== "pickFiles canceled") {
    //   // Handle other errors here
    //   console.error("An error occurred while picking a file:", e);
    //   throw e;
    // }
  }
};

const getLogo = (logoOnly: boolean = false) => {
  // Get the hostname from window.location.hostname
  const hostname = window.location.hostname;

  // Use a regular expression to extract the word before ".com"
  const match = hostname.match(/([^.]+)\.com$/);
  let isVypzee = true;
  if (match) {
    const wordBeforeDotCom = match[1];
    console.log(wordBeforeDotCom);
    if (wordBeforeDotCom.toLowerCase() === "idigizen") isVypzee = false;
  } else {
    //console.log("Invalid hostname format or not ending with '.com'");
  }
  if (logoOnly) {
    return !isVypzee ? "assets/ilogo.png" : "assets/vypzee.avif";
  } else {
    return !isVypzee ? "assets/iDigizen_Logo.png" : "assets/vypzee_full.avif";
  }
};

const getName = () => {
  // Get the hostname from window.location.hostname
  const hostname = window.location.hostname;

  // Use a regular expression to extract the word before ".com"
  const match = hostname.match(/([^.]+)\.com$/);
  if (match) {
    const wordBeforeDotCom = match[1];
    console.log(wordBeforeDotCom);
    if (wordBeforeDotCom.toLowerCase() === "idigizen") return "iDigizen";
    else return "VypZee";
  } else {
    return "VypZee";
  }
};

const getCurrentTime = (): string => {
  const now = new Date();

  // Use template literals to format the current time
  const currentTime = `${now.getHours().toString().padStart(2, "0")}:
                    ${now.getMinutes().toString().padStart(2, "0")}:
                    ${now.getSeconds().toString().padStart(2, "0")}`;
  return currentTime;
};

const encodeNumber = (number: number): string => {
  const encodedString = btoa(String.fromCharCode(number));
  return encodedString;
};

// Decode a Base64 string to a number
const decodeNumber = (encodedString: string): number => {
  const decodedChar = atob(encodedString).charCodeAt(0);
  return decodedChar;
};

/**********************************************************************************
 * Name: fetchImgThumbnailData
 * Description: Utility function to fetch the image data and transform that into a
 *              dataURI so that we do not have to wait for async image loads.
 *              This function will return a promise that resolves with the dataURI
 **********************************************************************************/
const fetchImgThumbnailData = async (
  imgName: string,
  isUrlFullyQualified: boolean = false,
  percentage?: number,
  noAvif?: boolean
) => {
  const isMobile = window.innerWidth < mobileWidthBreakpoint;
  try {
    let url: string;
    if (imgName.startsWith("blob")) {
      return imgName;
    }

    if (!isUrlFullyQualified) {
      percentage = percentage ? percentage : 40;
      url = `${IDIGIZEN_BACKEND_URL}/${getFileThumbnailDB}?fileName=${imgName}&percentage=${percentage}&Authorization=${API_SECURE_KEY}&isMobile=${isMobile}${
        noAvif ? "&noAvif=true" : ""
      }`;
    } else url = imgName;
    url += `&src=fetchImgThumbnailData`;
    const res = await axios.get(url, { responseType: "blob" });
    const dataURL = URL.createObjectURL(res.data);
    return dataURL;
  } catch (error) {
    console.error("Error fetching or processing image:", error);
    throw error;
  }
};

/**********************************************************************************
 * Name: promptFileDownload
 * Description: Utility function to prompt the user to download the file based on the
 *             response from the server.
 **********************************************************************************/
const promptFileDownload = async (response: any) => {
  try {
    console.log("promptFileDownload triggered");
    // Check if the response is successful (status code 200)
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    if (response.headers["content-type"].includes("application/json")) {
      return; //normal response nothing to download
    }

    let fileType = response.headers["content-type"];

    let blob = new Blob([response.data], {
      type: fileType,
    }); // This assumes the response is a binary blob

    // Check the Content-Disposition header
    const [type, fileExtension] = fileType.split("/");
    if (blob && blob.size > 0) {
      let fileName = `donwnload.${fileExtension}`;

      // Create a Blob URL and initiate download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName; // Set the desired filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      console.log("No attachment so nothing to download");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const generateVCard = (name, phone, email, website, organization) => {
  //bulleted list separated by *
  // The default 0 number is saved because android doesnt show contact default w/o phone number
  const phoneNumbersList = phone ? phone.split("*") : ["00000 00000"];

  //create the phoneNumber string for the vCard
  const phoneNumberTypes = [
    "CELL",
    "HOME",
    "WORK",
    "MAIN",
    "PAGER",
    "FAX",
    "OTHER",
  ];

  let phoneStr = "",
    idx = 0;
  for (const phoneNumberType of phoneNumberTypes) {
    if (phoneNumbersList.length === idx) {
      break; //done all phoneNumbers are appended
    }
    phoneStr =
      phoneStr + `TEL;TYPE=${phoneNumberType}:${phoneNumbersList[idx]}\n`;
    idx += 1;
  }

  return `
BEGIN:VCARD
VERSION:3.0
N:${organization};${name} ${organization ? "@" : ""};;;
FN:${name}${organization ? ` @ ${organization}` : ""}
${phoneStr}
EMAIL:${email ? email : ""}
URL:${website}
END:VCARD
`;
};

const downloadVCard = (name, phone, email, website, organization) => {
  const vCardData = generateVCard(name, phone, email, website, organization);
  const blob = new Blob([vCardData], { type: "text/vcard" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `contact-${name}-${organization}.vcf`;
  document.body.appendChild(a);
  a.addEventListener("click", function () {
    // This code will run when the download is initiated
    console.log("Download initiated");

    // Use a setTimeout to indicate download completion after a delay
    setTimeout(function () {
      document.body.removeChild(a);
    }, 1000); // Adjust the delay (in milliseconds) as needed
  });

  a.click();
  return true;
};

const getValidWhatsAppPhone = (phone: string): string => {
  return phone.replace(/\s+/g, "");
};

const getCurrentDateISO = (date: Date = undefined) => {
  const currentDate = date ? date : new Date();
  const isoDate = currentDate
    .toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1"); // Swap day and month to get YYYY-MM-DD format
  return isoDate;
};

const getCurrentDateString = () => {
  const isoDate = getCurrentDateISO();
  return getPrintDateDDMMYYYY(isoDate);
};

const callPhone = (phone: string) => {
  // window.open(`tel:${phone}`);
  window.location.href = `tel:${phone}`;
};

const getCashbackPercLoyalty = (coinPerSpend: number): string => {
  return `${((1 / coinPerSpend) * 100).toFixed(2)}%`;
};

const createDateFromDDMMYYYY = (dateString: string): Date => {
  // Extract day, month, and year from the input string
  const day = parseInt(dateString.substring(0, 2), 10);
  const month = parseInt(dateString.substring(2, 4), 10) - 1; // Month is zero-based
  const year = parseInt(dateString.substring(4, 8), 10);

  // Create Date object
  const dateObject = new Date(year, month, day);

  return dateObject;
};

const createISODateFromDDMMYYYY = (dateString: string): string => {
  if (!dateString) return "";
  return getCurrentDateISO(createDateFromDDMMYYYY(dateString));
};

const carryForardBhpSearch = ["styleId"];

const getSeoUrl = (
  inp: SeoUrlDetailsType,
  marketUrlOnly: boolean = false,
  searchParams: string = ""
): string => {
  let url = IDIGIZEN_FRONTEND_URL;
  //url = "http://test.myapp.localhost:8100/";

  if (!inp?.stateUrl || !inp?.cityUrl || !inp?.marketUrl) {
    return undefined; //do not return a valid SeoUrl if any of the required fields are missing
  }

  url += `${inp?.stateUrl}/${inp?.cityUrl}/${inp?.marketUrl}`;
  if (!marketUrlOnly) url += inp?.shopUrl ? `/${inp.shopUrl}` : "";
  let searchP = !searchParams ? "" : searchParams;
  const searchParamsL = getParamsFromSearchString(window.location.search);
  for (const param of Object.keys(searchParamsL)) {
    if (carryForardBhpSearch.includes(param)) {
      searchP += `${!searchP ? "?" : "&"}${param}=${searchParamsL[param]}`;
    }
  }
  if (searchP) url += searchP;
  return url;
};

const isFullyQualifiedUrl = (domain: string): boolean => {
  return domain.startsWith("https://") || domain.startsWith("http://");
};

const presentLocalStorageItem = (key: string): boolean => {
  return window.localStorage.getItem(key) !== null;
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
};

const setLocalStorageItem = (
  key: string,
  value: string,
  bypassIfPresent: boolean = false
) => {
  if (bypassIfPresent && presentLocalStorageItem(key)) {
    return;
  }
  window.localStorage.setItem(key, value);
};

const getLocalStorageItem = (key: string): string | null => {
  return window.localStorage.getItem(key);
};

const isBot = (): boolean => {
  const knownBots = [
    "Googlebot",
    "Bingbot",
    "Slurp",
    "DuckDuckBot",
    "Baiduspider",
    "YandexBot",
    "Mediapartners-Google",
  ];
  const userAgent = navigator.userAgent;
  return knownBots.some((bot) => userAgent.includes(bot));
};

const isMalware = (): boolean => {
  const userAgent = navigator.userAgent;
  const headlessUserAgents = [
    "HeadlessChrome",
    "PhantomJS",
    // Add other known headless user agents here
  ];

  return headlessUserAgents.some((agent) => userAgent.includes(agent));
};

export {
  isMalware,
  isBot,
  removeLocalStorageItem,
  presentLocalStorageItem,
  setLocalStorageItem,
  getLocalStorageItem,
  isFullyQualifiedUrl,
  getSeoUrl,
  createDateFromDDMMYYYY,
  createISODateFromDDMMYYYY,
  getCashbackPercLoyalty,
  getCurrentDateString,
  callPhone,
  getCurrentDateISO,
  getValidWhatsAppPhone,
  generateVCard,
  downloadVCard,
  encodeNumber,
  decodeNumber,
  getCurrentTime,
  getName,
  getLogo,
  getPhoto,
  transformTableStructForCluster,
  checkDomainName,
  goToGoogleMapsNav,
  getCurrentLocation,
  convertImgJsonToObj,
  getRotationInfo,
  getDateIso,
  getSubjectTraitsList,
  getPercentage,
  getAge,
  getImgUrlFromFormData,
  getBlobFromDataURI,
  convertImgToFormData,
  convertFileToFormData,
  getPrintDateDDMMYYYY,
  searchStringSuccess,
  isPhoneValid,
  getParamsFromSearchString,
  getMonthNameFromMonthNum,
  isEmailValid,
  keyExists,
  getTimeDurationInMinutes,
  getStudentAccolades,
  convertArrayToBlob,
  convertBlobToArray,
  getDateStringFromDateObj,
  getDateStringFromDateISO,
  getDateMonth,
  convertMonthIndexToMonthNumberString,
  addTimeHHMM,
  getMonth,
  getTimeFromHHMM,
  getTimeFromAmPm,
  subtractTimeHHMM,
  getSchoolName,
  convertToFormData,
  getYearList,
  onClickAction,
  splitAccoladesInYearChunks,
  fetchImgThumbnailData,
  compressBlob,
  compressImage,
  promptFileDownload,
  monthStrToNum,
};
