import { StringLiteral } from "@babel/types";
import axios from "axios";
import gql from "graphql-tag";
import { type } from "os";
import { API_SECURE_KEY, IDIGIZEN_BACKEND_URL } from "../types/constants";
import { getSessionDetails } from "./cookies";

const getTermListQuery = gql`
  query allSessions($where: JSON) {
    allSessions(where: $where) {
      totalCount
      Sessions {
        id
        name
        schoolid
      }
    }
  }
`;

const getAllEventMatchTypeScoreFormatGql = gql`
  query getAllEventScoreFormat($where: JSON) {
    allEventsmatchtypetables(where: $where) {
      Eventsmatchtypetables {
        id
        name
        EventMatchTypeId {
          Eventsmatchscorings {
            id
            label
            defaultshow
            index
          }
        }
      }
    }
  }
`;

const getEventMatchTypeGql = gql`
  query getEventMatchType($where: JSON) {
    allEventdetails(where: $where) {
      Eventdetails {
        matchtypeid
        isteamevent
      }
    }
  }
`;

const getAllEduJobsGql = gql`
  query allEduJobs {
    allEducationqualificationtables {
      Educationqualificationtables {
        id
        type
        name
        rel_qualid_idx {
          Jobeligibilitytables {
            id
            relJobidIdxrel {
              Jobmasters {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

const getAllJobsEduGql = `
query allJobsEduQual {
  allJobmasters {
    Jobmasters {
      id
      title
      rel_jobid_idx {
        Jobeligibilitytables {
          id
          relQualidIdxrel {
            Educationqualificationtables {
              id
              type
              name
            }
          }
        }
      }
    }
  }
}
`;

const testGql = gql`
  query testQuery($where: JSON) {
    allUserdata(where: $where) {
      Userdata {
        id
      }
    }
  }
`;

const getStudentAccoladeQuery = gql`
  query allAccolades($where: JSON) {
    allUserdata(where: $where) {
      Userdata {
        id
        firstname
        lastname
        profileimage
        biography
        dob
        DocumentUser {
          totalCount
          Documents {
            doctitle
            filename
            usercategory
            id
            what
            type
            when
            accolatetype
            where
            docdescription
            docgrade
            categoryid
            activityid
            documentcatrel {
              Clusterstables {
                name
              }
            }
            documentactivityrel {
              Ecaactivityjobtitles {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const getEventCertGql = `
  query getEventCert($where: JSON) {
    allDocuments(where: $where) {
      Documents {
            doctitle
            filename
            id
            what
            when
            accolatetype
            where
            category
            docdescription
            documentuserrel {
              Userdata {
                firstname
                lastname
              }
            }
      }
    }
  } 
`;

const getSubjectTraitsListGql = gql`
  query allSub($where: JSON) {
    allSubjects(where: $where) {
      Subjects {
        id
        name
        subject_id_mapping_idx {
          Subjectinteltraitmappings {
            intelTraitIdMappingIdxrel {
              Traitsintelligencelists {
                id
                name
                type
              }
            }
          }
        }
      }
    }
  }
`;

const getStudentList = gql`
  query allStudents($where: JSON) {
    allUserdata(where: $where) {
      totalCount
      Userdata {
        id
        firstname
        lastname
        DocumentUser {
          totalCount
        }
      }
    }
  }
`;

interface addSubjectGqlTypes {
  active: number;
  schoolid: number;
  name: string;
  grade: "Y" | "N";
}

const getSubjectListGql = gql`
  query allSubjects($where: JSON) {
    allSubjects(where: $where) {
      Subjects {
        name
        id
      }
    }
  }
`;

const addSubjectGql = gql`
  mutation addSubject($obj: SubjectInput!) {
    saveSubject(obj: $obj) {
      id
    }
  }
`;

interface addMarksGqlType {
  class: string;
  marks: number;
  maxmarks: number;
  studentid: string;
  subjectmasterid: string;
  subjectname: string;
  createdby: string;
  documentid: string;
  active: number;
}

const addMarksGql = gql`
  mutation addMarks($obj: StudentmarksInput!) {
    saveStudentmarks(obj: $obj) {
      id
    }
  }
`;

const removeMarksGql = gql`
  mutation removeMarks($where: JSON, $data: JSON) {
    StudentmarksUpsertWithWhere(where: $where, data: $data) {
      id
    }
  }
`;

const removeDocumentGql = gql`
  mutation deleteDocument($where: JSON, $data: JSON) {
    DocumentsUpsertWithWhere(where: $where, data: $data) {
      id
    }
  }
`;

const getMarksGql = gql`
  query getStudentMarks($where: JSON) {
    allStudentmarks(where: $where) {
      Studentmarks {
        class
        id
        marks
        maxmarks
        subjectmasterid
        subjectname
        documentid
      }
    }
  }
`;

const deleteMarksGql = gql`
  mutation deleteMarks($id: Int) {
    StudentmarksDeleteById(id: $id)
  }
`;

const delJobEduRelGql = gql`
  mutation deleteJobEduRel($id: Int) {
    JobeligibilitytableDeleteById(id: $id)
  }
`;

const getTraitsIntelligenceListGql = gql`
  query getTraitsIntelList($where: JSON) {
    allTraitsintelligencelists(where: $where) {
      Traitsintelligencelists {
        name
        type
        description
        id
      }
    }
  }
`;

interface addDocumentGqlType {
  type: string;
  accolatetype: number;
  docdescription: string;
  userid: string | number;
  when: string;
  what: string;
  docgrade: string;
  categoryid: number;
  activityid: number;
  usercategory: string;
}

const addDocumentGql = gql`
  mutation addDocument($obj: DocumentsInput!) {
    saveDocuments(obj: $obj) {
      id
    }
  }
`;

const setCategoryDb = gql`
  mutation updateCategory($where: JSON, $data: JSON) {
    DocumentsUpsertWithWhere(where: $where, data: $data) {
      id
    }
  }
`;

const updateEventDetailsGql = gql`
  mutation updateEvent($where: JSON, $data: JSON) {
    EventdetailsUpsertWithWhere(where: $where, data: $data) {
      id
    }
  }
`;

const getAllEcaJobIntelTraitsGql = gql`
  query allEcaJobIntelTraits {
    allEcaactivityjobtitles {
      Ecaactivityjobtitles {
        name
        id
        eca_job_idx {
          Ecajobsinteltraitsmappings {
            intelTraitsIdxrel {
              Traitsintelligencelists {
                id
                name
                type
              }
            }
          }
        }
        clusterIdIdxrel {
          Clusterstables {
            id
            name
            type
          }
        }
      }
    }
  }
`;

const getAllEcaActivitiesPerCatGql = gql`
  query allEcaCat($where: JSON) {
    allClusterstables(where: $where) {
      Clusterstables {
        id
        name
        type
        image
        cluster_id_idx(where: { active: 1 }) {
          Ecaactivityjobtitles {
            id
            name
          }
        }
      }
    }
  }
`;

const getAllJobsGql = gql`
  query allJobs($where: JSON) {
    allJobmasters(where: $where) {
      Jobmasters {
        cluster
        clusterpriority
        description
        id
        title
        picture
        jobactivity_jobidx {
          Jobactivities {
            activity
            description
            id
          }
        }
      }
    }
  }
`;

const getAllInterestsGql = gql`
  query allInterests($where: JSON) {
    allInterestmasters(where: $where) {
      Interestmasters {
        id
        category
        categorypriority
        title
        type
        activities
        description
        picture
      }
    }
  }
`;

const getAllEcaActivitiesGql = gql`
  query getAllEcaAct($where: JSON) {
    allEcaactivities {
      Ecaactivities {
        id
        name
        description
        parentkey
        ssiecadata_ecaid(where: $where) {
          totalCount
          Ssiecasurveydata {
            id
            ssiid
            userid
            score
            active
          }
        }
      }
    }
  }
`;

const getSchoolLogoGql = gql`
  query getLogoName($where: JSON) {
    allSchools(where: $where) {
      Schools {
        logo
      }
    }
  }
`;

const getSsiScoringIndexGql = gql`
  query getScoringIndex {
    allSsimeasurementindices {
      Ssimeasurementindices {
        id
        label
        score
      }
    }
  }
`;

const getAllSsiGql = gql`
  query getAllSsi {
    allSsitables {
      Ssitables {
        id
        description
        shortdescription
        name
        parentkey
      }
    }
  }
`;

const getEcaSsiScoresGql = gql`
  query getAllMarksEca($where: JSON) {
    allSsiecasurveydata(where: $where) {
      Ssiecasurveydata {
        id
        ssiid
        score
      }
    }
  }
`;

const getEventMatchesGql = gql`
  query getEventScore($where: JSON) {
    allEventmatchestables(where: $where) {
      Eventmatchestables {
        name
        iscomplete
        player1id
        player2id
        createdby
        date
        id
        location
        score
        winnerid
        remarks
        matchesCreatorIdrel {
          Userdata {
            firstname
            lastname
          }
        }
      }
    }
  }
`;

interface matchDetailsGqlType {
  active?: boolean;
  createdby?: string;
  date?: string;
  eventid: string;
  id?: string;
  iscomplete: boolean;
  location?: string;
  name: string;
  player1id: string | number;
  player2id: string | number;
  remarks?: string;
  score?: string;
  updatedby?: string;
  winnerid?: string | number;
}

const createEventMatchEntryGql = gql`
  mutation addMatch($data: JSON) {
    EventmatchestableCreate(data: $data) {
      id
    }
  }
`;

const updateEventMatchEntryGql = gql`
  mutation updateMatch($where: JSON, $data: JSON) {
    EventmatchestableUpsertWithWhere(where: $where, data: $data) {
      id
    }
  }
`;

const getUserDetailsGql = gql`
  query getUserDetails($where: JSON) {
    allUserdata(where: $where) {
      Userdata {
        id
        firstname
        lastname
        email
        phonenumber
        biography
        profileimage
        gender
        dob
      }
    }
  }
`;

const getEnterpriseDetailsGql = gql`
  query getEnterpriseDetails($where: JSON) {
    allSchools(where: $where) {
      Schools {
        id
        schoolname
        website
        email
        logo
        description
        tagline
        upihandle
        phonenumber
        qrcodeimg
        schooltype
        contactpersonphone
      }
    }
  }
`;

const changePasswordGql = gql`
  mutation UserdataUpdateClientPassword(
    $userId: String
    $oldPassword: String
    $newPassword: String
  ) {
    UserdataUpdateClientPassword(
      userId: $userId
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;

const fetchQueries = async (query, variables) => {
  const { token } = getSessionDetails();
  console.log("fetchQueries : ", { variables });
  try {
    const data = await fetch(`${IDIGIZEN_BACKEND_URL}/graphql`, {
      headers: {
        "Content-Type": "application/json",
        access_token: token,
      },
      method: "POST",
      body: JSON.stringify({
        query,
        variables,
        Authorization: API_SECURE_KEY,
      }),
    });
    return data.json();
  } catch (e) {
    console.log("Error in GQL ", e);
  }
};

export {
  fetchQueries,
  getEnterpriseDetailsGql,
  getUserDetailsGql,
  createEventMatchEntryGql,
  updateEventMatchEntryGql,
  getAllEventMatchTypeScoreFormatGql,
  getEventMatchesGql,
  getStudentList,
  updateEventDetailsGql,
  setCategoryDb,
  getTermListQuery,
  addDocumentGql,
  getStudentAccoladeQuery,
  getSubjectListGql,
  addMarksGql,
  removeMarksGql,
  removeDocumentGql,
  getMarksGql,
  deleteMarksGql,
  addSubjectGql,
  getTraitsIntelligenceListGql,
  getAllEcaJobIntelTraitsGql,
  getSubjectTraitsListGql,
  getAllEcaActivitiesPerCatGql,
  getAllInterestsGql,
  getAllJobsGql,
  getAllEduJobsGql,
  getAllJobsEduGql,
  delJobEduRelGql,
  getAllEcaActivitiesGql,
  getSchoolLogoGql,
  getSsiScoringIndexGql,
  getAllSsiGql,
  getEcaSsiScoresGql,
  getEventMatchTypeGql,
  getEventCertGql,
  changePasswordGql,
  testGql,
};

export type {
  addDocumentGqlType,
  addMarksGqlType,
  addSubjectGqlTypes,
  matchDetailsGqlType,
};
