const deepCopy = (copyFrom) => {
  if (copyFrom === undefined || copyFrom === null) {
    return; //null and undefined are objects in JS
  }
  const type = typeof copyFrom;
  //console.log("deepCopy ", type, copyFrom, Array.isArray(copyFrom));
  if (type === "object") {
    if (Array.isArray(copyFrom)) {
      const copyArr = [];
      for (const el of copyFrom) {
        copyArr.push(deepCopy(el));
      }
      return copyArr;
    } else if (copyFrom instanceof Blob) {
      return new Blob([copyFrom], { type: copyFrom.type });
    } else {
      const keys = Object.keys(copyFrom);
      const copyObj = {};
      for (const key of keys) {
        copyObj[key] = deepCopy(copyFrom[key]);
      }
      return copyObj;
    }
  } else {
    if (type === "string") {
      return (" " + copyFrom).slice(1);
    } else return copyFrom;
  }
};
export default deepCopy;
