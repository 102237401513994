import { IonSpinner } from "@ionic/react";
import React from "react";

const LazyLoaderSpinner: React.FC = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IonSpinner />
    </div>
  );
};

export default LazyLoaderSpinner;
