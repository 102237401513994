import React, { useEffect, useState } from "react";
import AlertWithoutExec from "../UI/AlertWithoutExec";
import { AlertWithExecTypes, AlertWithoutExecTypes } from "../types/data_types";
import { useHistory } from "react-router";
import AlertWithExec from "../UI/AlertWithExec";
import { getSessionDetails, removeSessionDetails } from "../common/cookies";
import {
  API_SECURE_KEY,
  DOMAIN_PATH,
  IDIGIZEN_BACKEND_URL,
  logoutRoute,
} from "../types/constants";
import axios from "axios";
import { useAppDispatch, useLoginLogout, useStringToast } from "../app/hooks";
import { loginInfoActions } from "../store/login-info-slice";

const LogoutComponent: React.FC<{
  logout: boolean;
  onDidDismiss: () => void;
}> = (props) => {
  const history = useHistory();
  const { token } = getSessionDetails();
  const dispatch = useAppDispatch();
  const presentToast = useStringToast();
  const { logoutHandler } = useLoginLogout();

  useEffect(() => {
    if (props.logout) {
      console.log("Logout called");
      setAlert({
        executeFunc: async () => {
          logoutHandler();
        },
        funcInputs: undefined,
        message: "Alternate login detected on another device. Logging out",
        open: true,
      });
    }
  }, [props.logout]);

  const [alert, setAlert] = useState<AlertWithExecTypes>({
    executeFunc: undefined,
    funcInputs: undefined,
    message: "",
    open: false,
  });
  return (
    <>
      <AlertWithExec
        openAlert={alert}
        setOpenAlert={setAlert}
        disableCancel={true}
      />
    </>
  );
};

export default LogoutComponent;
