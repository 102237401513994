import { AppDispatch, RootState } from "../store";
import { TypedUseSelectorHook } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { useIonToast } from "@ionic/react";
import axios, { AxiosError } from "axios";
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";
import { ImageOptions, Photo, Camera } from "@capacitor/camera";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import {
  API_SECURE_KEY,
  getCompletion,
  IDIGIZEN_BACKEND_URL,
  logoutRoute,
  saveUserInteractionDB,
  userInfoRouter,
  UserInteractionRecordType,
  userSessionTimeKey,
  userUIDKey,
  vCardGetDB,
} from "../types/constants";
import {
  getSessionDetails,
  removeSessionDetails,
  storeSessionDetails,
} from "../common/cookies";
import {
  downloadVCard,
  getLocalStorageItem,
  getParamsFromSearchString,
  isBot,
  isEmailValid,
  isMalware,
  isPhoneValid,
  presentLocalStorageItem,
  setLocalStorageItem,
} from "../common/commonFunctions";
import { loginInfoActions } from "../store/login-info-slice";
import { teacherInfoActions } from "../store/teacher-info-slice";
import ReactGA from "react-ga";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useCustomToast = () => {
  const [present] = useIonToast();
  const presentToast = (e: AxiosError): void => {
    const msg =
      e?.response?.data && e.response.data["message"]
        ? e.response.data["message"]
        : "Error in reading/ writing data.";
    present({
      message: msg,
      position: "middle",
      duration: 1500,
    });
  };
  return presentToast;
};

export const useGetUserInfo = () => {
  const dispatch = useAppDispatch();
  const { id, token, email } = getSessionDetails();
  const getUserInfo = async (idIn: string | number = undefined) => {
    //console.log("Called get user info");
    const res = await axios.get(`${IDIGIZEN_BACKEND_URL}/${userInfoRouter}`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        Authorization: API_SECURE_KEY,
        id: !!idIn ? idIn : id,
        token,
        email,
      },
    });
    //console.log(res);
    dispatch(teacherInfoActions.updateInfo(res.data.data));
    if (res.data.data.schoolId) {
      storeSessionDetails({ schoolId: res.data.data.schoolId });
    }
  };
  return { getUserInfo };
};

export const useLoginLogout = () => {
  const dispatch = useAppDispatch();
  const presentToast = useCustomToast();
  const presentStringToast = useStringToast();
  const history = useHistory();
  const location = useLocation();

  /*************************************************************************
   * Name: getUserInfo
   ************************************************************************/
  const getUserInfo = async () => {
    const { id, token, email } = getSessionDetails();
    try {
      console.log("Called getUserInfo");
      const res = await axios.get(`${IDIGIZEN_BACKEND_URL}/${userInfoRouter}`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          Authorization: API_SECURE_KEY,
          id,
          token,
          email,
        },
      });
      console.log(res);
      dispatch(teacherInfoActions.updateInfo(res.data.data));
    } catch (e) {
      console.log("Error ", e);
      presentToast(e);
      throw e;
    }
  };
  /*************************************************************************
   * Name: navigationHandler
   ************************************************************************/
  const navigationHandler = async (
    res = null,
    beforeNavCallback: () => boolean = undefined
  ) => {
    console.log("Called navigation handler");
    const params = getParamsFromSearchString(location.search);

    dispatch(loginInfoActions.updateInfo({ loginInfo: true }));
    let doDefaultNav = true;
    if (beforeNavCallback) {
      doDefaultNav = beforeNavCallback();
    }

    try {
      await getUserInfo();
      if (doDefaultNav) {
        if (res && res.landingPage) {
          history.push(res.landingPage);
        } else {
          if (!params.intPath) {
            history.push("/landing-page");
          } else {
            let path = `/${params.intPath}`;
            let searchParams = "";
            for (const key of Object.keys(params)) {
              if (key !== "intPath") {
                searchParams =
                  searchParams +
                  `${searchParams ? "&" : "?"}` +
                  `${key}=${params[key]}`;
              }
            }
            console.log(
              "Change path to ",
              path,
              " with search params ",
              searchParams
            );
            history.push(`${path}${searchParams}`);
          }
        }
      }
    } catch (e) {}
  };
  /*************************************************************************
   * Name: emailLoginHandler
   * Description: Initiate the login request with email and password to backend
   *              Get user data on success. Store it in the cookie for session
   *              storage
   **************************************************************************/
  const loginHandler = async (
    inEmail: string,
    inPhone: string,
    password: string,
    role: string,
    beforeNavCallback: () => boolean,
    isSignUp: boolean = false,
    confirmPassword: string = ""
  ): Promise<{ success: boolean; message?: string }> => {
    try {
      let email = inEmail.trimEnd();
      let phone = inPhone.trimEnd();
      let queryParams = "";

      if (!email && !phone) {
        return {
          success: false,
          message: "Please provide a valid email / phone number",
        };
      }

      if (isEmailValid(email) || (!isSignUp && isPhoneValid(email))) {
        //support for legacy logins with phoneNumbers
        queryParams += `email=${encodeURIComponent(email)}&`;
      } else {
        return {
          message: "Please provide a valid email.",
          success: false,
        };
      }

      //additional checks for signUp
      if (isSignUp) {
        if (password !== confirmPassword) {
          return {
            success: false,
            message: "The passwords do not match",
          };
        }

        //validate the phone number.
        if (isPhoneValid(phone)) {
          queryParams += `phonenumber=${encodeURIComponent(phone)}`;
        } else {
          return {
            message:
              "Please provide a valid 10 digit phone number. Do not include '+' or country code.",
            success: false,
          };
        }
      }

      if (!password) {
        return { message: "Password cannot be left blank!", success: false };
      }

      let _password = encodeURIComponent(password);
      queryParams += `&password=${_password}&role=${role}&Authorization=${API_SECURE_KEY}`;
      let registrationResponse = null;

      if (isSignUp) {
        registrationResponse = await axios.post(
          `${IDIGIZEN_BACKEND_URL}/register?${queryParams}`
        );

        if (registrationResponse.data.status !== "success") {
          return {
            message: `Registration failed. ${registrationResponse.data.message}`,
            success: false,
          };
        }
      }
      let res = await axios.post(
        `${IDIGIZEN_BACKEND_URL}/login?${queryParams}`
      );

      //Proceed if login success
      if (res.data.status === "success") {
        console.log(
          "Login succeeded ",
          res.data,
          registrationResponse ? registrationResponse.data : ""
        );
        storeSessionDetails(res.data);
        await navigationHandler(
          registrationResponse ? registrationResponse.data : null,
          beforeNavCallback
        );
        return { success: true };
      }

      if (res.data.errorCode === "CONCURRENT_LOGIN") {
        res = await axios.post(
          `${IDIGIZEN_BACKEND_URL}/concurrency?continue=true&userId=${res.data.userId}&accessToken=${res.data.token}&Authorization=${API_SECURE_KEY}`
        );
        console.log("Login succeeded ", res.data);
        storeSessionDetails(res.data);
      } else {
        console.log(res);
        return {
          success: false,
          message: `Login failed, ${res.data.message}`,
        };
      }
      if (registrationResponse) {
        await navigationHandler(registrationResponse.data, beforeNavCallback);
      } else {
        await navigationHandler(undefined, beforeNavCallback);
      }
      return { success: true };
    } catch (e) {
      console.log("Error in loginHandler ", e);
      presentToast(e);
      return { success: false };
    }
  }; //End of loginHandler

  /*************************************************************************
   * Name: logoutHandler
   * Description: Initiate the login request with email and password to backend
   *              Get user data on success. Store it in the cookie for session
   *              storage
   **************************************************************************/
  const { token } = getSessionDetails();
  const logoutHandler = async (url: string = "") => {
    console.log("Logout token ", token);
    removeSessionDetails();
    try {
      if (!token) {
        history.push("/home");
        return;
      }

      const res = await axios.get(`${IDIGIZEN_BACKEND_URL}/${logoutRoute}`, {
        headers: { "Content-Type": "application/json" },
        params: {
          Authorization: API_SECURE_KEY,
          access_token: token,
        },
      });
      console.log("Logout called ", res);
      if (res.data && res.data.status === "success") {
        removeSessionDetails();
        dispatch(loginInfoActions.updateInfo({ loginInfo: false }));
        console.log("Push home stack ", history);
        if (!!url) {
          window.location.assign(url);
        } else {
          history.push("/home");
        }
      }
    } catch (err) {
      presentStringToast(`Error logging out : Error =  ${err.data.message}`);
    }
  };

  return { loginHandler, logoutHandler, navigationHandler };
};

export const useStringToast = () => {
  const [present] = useIonToast();
  const presentToast = (msg: string): void => {
    present({
      message: msg,
      position: "middle",
      duration: 1500,
    });
  };
  return presentToast;
};

export const useRecordUserInteractions = () => {
  const generateUniqueId = (): string => {
    const timestamp = new Date().getTime(); // current time in milliseconds
    const randomComponent = Math.random().toString(36).substring(2, 9); // random string
    return `${timestamp}-${randomComponent}`;
  };

  const getUID = (): string => {
    if (!presentLocalStorageItem(userUIDKey)) {
      const uid = generateUniqueId();
      setLocalStorageItem(userUIDKey, uid, true);
      return uid;
    } else {
      return getLocalStorageItem(userUIDKey);
    }
  };

  const getSessionId = (): string => {
    const currentTime = new Date().getTime();
    let retval: string = "";
    if (!presentLocalStorageItem(userSessionTimeKey)) {
      retval = `${currentTime}`;
      setLocalStorageItem(userSessionTimeKey, retval);
    } else {
      const lastTime = parseInt(getLocalStorageItem(userSessionTimeKey));
      // 10min in ms
      if (currentTime - lastTime > 600000) {
        retval = `${currentTime}`;
        setLocalStorageItem(userSessionTimeKey, retval);
      } else {
        retval = `${lastTime}`;
      }
    }
    return retval;
  };

  const recordAction = (inp: UserInteractionRecordType): void => {
    // utils.ts
    const hostname = window.location.hostname;
    if (hostname === "localhost" || hostname.endsWith(".localhost")) return;
    // Check for IP address in private ranges
    const localIpPattern =
      /^(10\.\d{1,3}\.\d{1,3}\.\d{1,3}|192\.168\.\d{1,3}\.\d{1,3}|172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3})$/;
    if (localIpPattern.test(hostname)) {
      return;
    }

    // if (isBot()) {
    //   return;
    // }
    const { id } = getSessionDetails();
    const uid = getUID();
    const session = getSessionId();
    inp.url = window.location.href;
    inp.userAgent = navigator.userAgent;
    let data = axios.post(
      `${IDIGIZEN_BACKEND_URL}/${saveUserInteractionDB}`,
      inp,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: API_SECURE_KEY,
        },
        params: {
          Authorization: API_SECURE_KEY,
          id,
          uid,
          session,
          isBot: isMalware() || isBot(),
        },
      }
    );
  };
  return recordAction;
};

export const useCamera = (): ((options: ImageOptions) => Promise<Photo>) => {
  const getPhoto = async (options: ImageOptions): Promise<Photo> => {
    const image: Photo = await Camera.getPhoto(options);
    console.log("Image data ", image);
    const base64String = await base64FromPath(image.webPath);
    image.base64String = base64String;
    return image;
  };
  return getPhoto;
};

export const useSaveVcard = (): ((
  domain: string,
  isMarket: boolean,
  urlParams?: string,
  isRwa?: boolean,
  userId?: number | string
) => Promise<boolean>) => {
  const { id, token, email, schoolId } = getSessionDetails();
  const saveVcard = async (
    domain: string,
    isMarket: boolean,
    urlParams?: string,
    isRwa?: boolean,
    userId?: string | number
  ) => {
    try {
      ReactGA.event({
        category: "test",
        action: "saveVCard",
        label: "saveVCard",
      });

      const response = await axios.get(
        `${IDIGIZEN_BACKEND_URL}/${vCardGetDB}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_SECURE_KEY,
          },
          params: {
            token,
            id,
            domain: domain,
            isMarket: isMarket,
            urlParams,
            rwa: isRwa,
            userId: userId,
          },
        }
      );
      console.log(response);
      const { name, phone, email, website, organization } =
        response.data.data.data;

      const downloadPopUp = downloadVCard(
        name,
        phone,
        email,
        website,
        isMarket && !userId ? (isRwa ? "Directory" : "Market") : organization
      );
      console.log("downloaded", downloadPopUp);
      return downloadPopUp;
    } catch (e) {
      console.log("Got error ", e);
    }
  };
  return saveVcard;
};

export const useGetCompletion = (): ((query: string) => Promise<string>) => {
  const presentToast = useCustomToast();

  const queryFunction = async (query: string): Promise<string> => {
    const { id, token, email, schoolId } = getSessionDetails();
    try {
      const res = await axios.post(
        `${IDIGIZEN_BACKEND_URL}/${getCompletion}`,
        JSON.stringify({ data: query }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_SECURE_KEY,
          },
          params: {
            id,
            email,
            schoolId,
            token,
          },
        }
      );
      // console.log(res);
      console.log("Got chat gpt reponse", res.data.data.choices[0].text);
      return res.data.data.choices[0].text;
    } catch (e) {
      console.log("Got error ", e);
      presentToast(e);
    }
  };

  return queryFunction;
};

export const useModalController = (
  name: string,
  modalOpenFunc: (inp: any) => void,
  modalCloseFunc: () => void
): [(inp: any) => void, () => void] => {
  const history = useHistory();
  const location = useLocation();

  const openModal = (inp: any) => {
    console.log("History: Called the openModal function");
    const locationStr = !!location.search ? `&${name}=true` : `?${name}=true`;
    history.push({
      pathname: location.pathname,
      search: location.search + locationStr,
    });
    modalOpenFunc(inp);
  };

  useEffect(() => {
    if (!location.search.includes(`${name}=true`)) {
      modalCloseFunc();
    }
  }, [location]);

  const closeModal = () => {
    console.log("Hist go back ", location, " ", name);
    if (location.search.includes(`${name}=true`)) {
      console.log("History go back");
      history.goBack();
    }
  };

  return [openModal, closeModal];
};

const userActionsTable = {
  adScan: "AD Scan",
  share: "Share",
  call: "Call",
  whatsapp: "Whatsapp",
  bookATable: "Book A Table",
  visitPage: "Visit Page",
  accessDenied: "Access Denied",
  expandDetails: "Expand Details",
  save: "Save",
  contact: "Contact",
  seeAll: "See All",
  search: "Search",
  hotKey: "HotKey",
  email: "Email",
  website: "Website",
  socialMedia: "Social Media",
  backToMarket: "Back To Market",
  localMarkets: "Backlink VypZee",
  customerLoyalty: "Customer Loyalty",
  pathFinder: "Path Finder",
  direction: "Direction",
  aboutUs: "About Us",
  combinedDirection: "Combined Direction",
  openSocialHandles: "Open Social Handles",
  categoryExpand: "Category Expand",
  categoryClick: "Category Click",
  bizCardCTAButton: "Biz Card CTA Button",
  triggerSearch: "Trigger Search",
  reviewStore: "Review Store",
  submitReview: "Submit Review",
  openProduct: "Open Product",
  openTrend: "Open Trend",
  browseProduct: "Browse Product",
  browseTrend: "Browse Trend",
  addRemoveProd: "Add Remove Prod",
  delProd: "Delete Prod",
  sendOrder: "Send Order",
  findPath: "Find Path",
};

export { userActionsTable };
