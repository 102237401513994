import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { keyExists } from "../common/commonFunctions";
import {
  IprofileAcademicScorePerClass,
  ProfileDataType,
  SubjectIntelligenceTraits,
} from "../types/data_types";

const initialState: {
  profileData: ProfileDataType;
  academicScore: IprofileAcademicScorePerClass[];
  subjectTraitsList: SubjectIntelligenceTraits[];
} = {
  profileData: {
    aboutMe: "",
    dateOfBirth: "",
    id: "",
    img: "",
    name: "",
    profileName: "",
  },
  academicScore: [],
  subjectTraitsList: [],
};

const iprofileInfoSlice = createSlice({
  name: "iprofile-info-slice",
  initialState,
  reducers: {
    updateIprofile: (
      state,
      action: PayloadAction<{ iprofileInfo: ProfileDataType }>
    ) => {
      state.profileData.aboutMe = action.payload.iprofileInfo.aboutMe;
      state.profileData.accolades = action.payload.iprofileInfo.accolades;
      state.profileData.dateOfBirth = action.payload.iprofileInfo.dateOfBirth;
      state.profileData.id = action.payload.iprofileInfo.id;
      state.profileData.img = action.payload.iprofileInfo.img;
      state.profileData.name = action.payload.iprofileInfo.name;
      state.profileData.profileName = action.payload.iprofileInfo.profileName;
    },
    updateAcademicScore: (
      state,
      action: PayloadAction<{ academicScore: IprofileAcademicScorePerClass[] }>
    ) => {
      state.academicScore = action.payload.academicScore;
    },
    updateSubjectTraitsList: (
      state,
      action: PayloadAction<{ subjectTraitsList: SubjectIntelligenceTraits[] }>
    ) => {
      state.subjectTraitsList = action.payload.subjectTraitsList;
    },
    updateInterestAccolade: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      if (keyExists(action.payload.key, state.profileData)) {
        state.profileData[action.payload.key] = [];
      }
      state.profileData[action.payload.key].push(action.payload.value);
    },
  },
});

export const iprofileInfoActions = iprofileInfoSlice.actions;
export default iprofileInfoSlice;
