import { keyExists } from "./commonFunctions";
//import { domain } from "../types/constants";

/************************************************************
 * Name: storeSessionDetails
 * Description: Initialization function to store user cookies
 *              based on successful login
 * Arguments: userData returned on login
 ************************************************************/
function storeSessionDetails(userData, timeMin = 0) {
  const sessionDetailKeys = [
    "token",
    "email",
    "firstname",
    "lastname",
    "id",
    "role",
    "profileimage",
    "schoolId",
    "session",
  ];

  const expirationDate = new Date();
  const days = 365;
  if (!!timeMin) {
    expirationDate.setTime(expirationDate.getTime() + timeMin * 60 * 1000);
  } else {
    expirationDate.setDate(expirationDate.getDate() + days);
  }

  console.log("userData =", userData);
  //store the session details as cookies for retrieval later
  for (const key of sessionDetailKeys) {
    if (!keyExists(key, userData)) {
      continue;
    }
    setCookie(key, userData[key], expirationDate);
  }
}

/************************************************************
 * Name: setCookie
 * Description: Helper function to set the cookie
 ************************************************************/
const setCookie = (key, val, expirationDate) => {
  try {
    let domain = getDomain();
    const cookieValue = encodeURIComponent(val) + `; domain=${domain};path=/`;
    console.log("Set cookie data ", key, " value ", cookieValue);
    let cookieStr = `${key}=${cookieValue}; `;
    if (expirationDate) {
      cookieStr += `expires=${expirationDate.toUTCString()}; `;
    }
    document.cookie = cookieStr;
  } catch (error) {
    console.log("Error setting cookie", error);
  }
};

/************************************************************
 * Name: removeSessionDetails
 * Description: Deletion function to remove user cookies
 *              based on successful logout
 * Arguments: userData returned on login
 ************************************************************/
function removeSessionDetails() {
  // Function to delete a cookie
  const domain = getDomain();
  function deleteCookie(name, path) {
    let cookieStr = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain};path=/`;
    document.cookie = cookieStr;
  }

  // Function to delete all cookies
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, _] = cookie.split("=");
    deleteCookie(cookieName);
  }
}

/************************************************************
 * Name: getSessionDetails
 * Description: Function to retrieve all the stored session
 *              details and return it as an object.
 ************************************************************/
function getSessionDetails() {
  const cookies = document.cookie.split(";");
  let sessionDetails = {};
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    const name = cookieName.trim();
    const val = decodeURIComponent(cookieValue);
    sessionDetails[name] = val;
  }
  // console.log("Session Details =", sessionDetails);
  return sessionDetails;
}

const getDomain = () => {
  const hostname = window.location.hostname;
  let domain = "";
  if (parseInt(hostname.split(".")[0])) {
    domain = hostname;
  } else {
    let domainParts = hostname.split(".");
    if (domainParts.length <= 2) {
      domain = hostname;
      return domain;
    } else {
      domain = hostname.split(".").slice(1).join(".");
    }
    domain = `.${domain}`;
  }
  console.log("domain =", domain);
  return domain;
};

export {
  setCookie,
  storeSessionDetails,
  getSessionDetails,
  removeSessionDetails,
};
