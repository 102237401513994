import { configureStore } from "@reduxjs/toolkit";
import headerNameInfoSlice from "./header-name-info-slice";
import iprofileInfoSlice from "./iprofile-slice";
import loginInfoSlice, { loginInfoActions } from "./login-info-slice";
import teacherInfoSlice from "./teacher-info-slice";
import screenWidthSlice from "./screen-width-slice";
import businessNameInfoSlice from "./business-name-info-slice";
import reviewCedentialsInfoSlice from "./review-credential-info-slice";

const store = configureStore({
  reducer: {
    teacherInfoSelection: teacherInfoSlice.reducer,
    loginInfoSelection: loginInfoSlice.reducer,
    headerNameInfoSelection: headerNameInfoSlice.reducer,
    businessNameInfoSelection: businessNameInfoSlice.reducer,
    iprofileInfoSelection: iprofileInfoSlice.reducer,
    screenWidth: screenWidthSlice.reducer,
    reviewCredintials: reviewCedentialsInfoSlice.reducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
